import { Grid, InputAdornment } from '@mui/material';
import circleCheckedActive from 'assets/svg/circle-checked-active.svg';
import circleCheckedInactive from 'assets/svg/circle-checked-in-active.svg';
import CurrencySymbol from 'components/shared/CurrencyFormat';
import MDBox from 'components/themed/MDBox';
import MDInput from 'components/themed/MDInput';
import MDTypography from 'components/themed/MDTypography';
import { Form, FormikProvider, useFormik } from 'formik';
import useAuthentication from 'hooks/useAuthentication';
import { enqueueSnackbar } from 'notistack';
import React, { useCallback } from 'react';
import { useReportLeadSaleMutation } from 'redux/services/spotdif/leads';
import * as Yup from 'yup';

interface LeadSaleStatusProps {
    leadId: string;
    soldAt: number | null;
}


const LeadSaleReport: React.FC<LeadSaleStatusProps> = ({ leadId, soldAt }) => {
    const { user } = useAuthentication();
    const [reportSale] = useReportLeadSaleMutation();


    const saleReportValidationSchema = Yup.object({
        isSold: Yup.boolean(),
        soldAt: Yup.number()
            .when('isSold', {
                is: true,
                then: (schema) => {
                    return schema
                        .required('Selling Price is Required')
                        .test('is-not-negative', 'Selling Price cannot be negative', (value) => value >= 0);
                },
            }),
    });
    const formik = useFormik({
        initialValues: {
            isSold: soldAt !== null && soldAt !== undefined,
            soldAt: soldAt || '',
        },
        validationSchema: saleReportValidationSchema,
        onSubmit: (values) => {
            reportSale({ leadId, soldAt: values.isSold ? parseFloat(`${values.soldAt}`) : null })
                .then(() => {
                    enqueueSnackbar('Lead Sale saved successfully', { variant: 'info', key: 'lead-sale' });
                });
        },
    });


    const debounceRef = React.useRef<number>(0);
    const scheduleSubmit = useCallback(() => {
        clearTimeout(debounceRef.current);
        debounceRef.current = window.setTimeout(() => {
            formik.handleSubmit();
        }, 1000);
    }, [formik]);

    const handleIsSoldChange = useCallback(() => {
        formik.setFieldValue('isSold', !formik.values.isSold);
        formik.setFieldValue('soldAt', 0);
        scheduleSubmit();
    }, [formik, scheduleSubmit]);

    const handlePriceChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        formik.setFieldValue('soldAt', e.target.value.trim());
        scheduleSubmit();
    }, [formik, scheduleSubmit]);

    return (
        <FormikProvider value={formik}>
            <Form onSubmit={formik.handleSubmit} className="spotdif-login-form">
                <div className="contract lead-sale">
                    <p>Sale Status</p>
                    <Grid container spacing={2} className="lead-sale--container">
                        <Grid
                            item xs={12} md={6}
                        >
                            <MDBox
                                onClick={handleIsSoldChange}
                                className="lead-sale--status"
                                sx={{
                                    cursor: 'pointer',
                                }}
                            >
                                <MDTypography>Made a Sale</MDTypography>
                                <img
                                    alt={formik.values.isSold ? 'Sale has been Made' : 'Sale has not been Made'}
                                    src={
                                        formik.values.isSold ? circleCheckedActive : circleCheckedInactive
                                    }
                                />
                            </MDBox>
                        </Grid>
                        <Grid
                            item xs={12} md={6}
                        >
                            {
                                formik.values.isSold && (
                                    <MDBox>
                                        <MDInput
                                            placeholder="Sale Amount"
                                            name="soldAt"
                                            value={formik.values.soldAt}
                                            onChange={handlePriceChange}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <strong><CurrencySymbol currencyCode={user?.currency} /></strong>
                                                    </InputAdornment>
                                                ),
                                            }}
                                            error={Boolean(formik.errors.soldAt)}
                                            helperText={formik.errors.soldAt}
                                        />
                                    </MDBox>
                                )
                            }
                        </Grid>
                    </Grid>
                </div>
            </Form>
        </FormikProvider>
    );
};

export default LeadSaleReport;
