import { ExpandedState, flexRender, useReactTable } from '@tanstack/react-table';
import { createColumnHelper, getCoreRowModel } from '@tanstack/table-core';
import reportRejected from 'assets/images/icons/decline.svg';
import reportAccepted from 'assets/images/icons/report-accepted.png';
import reportedLeads from 'assets/images/icons/reported-leads.png';

import validLeads from 'assets/images/icons/valid-leads.png';
import classNames from 'classnames';
import SpinningComponent from 'components/shared/SpinningComponent';
import { ILeadsLIstQuery, ILeadsListResponse } from 'contracts/requests/ILeadsListResponse';

import { LeadsBidData, LeadStatuses } from 'contracts/spotdif/LeadsBidData';
import React, { useCallback, useEffect, useMemo } from 'react';
import ExpandedRow from './ExpandedRow';

import ResponsiveTable from './ResponsiveTable';

interface TableProps {
    adminSerializedData: LeadsBidData[];
    query: ILeadsLIstQuery;
    setQuery: (arg: ILeadsLIstQuery) => void;
    adminLeadsData: ILeadsListResponse;
    loading: boolean;
}

const columnHelper = createColumnHelper<LeadsBidData>();

const Table: React.FC<TableProps> = ({ adminSerializedData, query, setQuery, loading, adminLeadsData }) => {
    const [expanded, setExpanded] = React.useState<ExpandedState>({});
    const getIcon = useCallback((status: LeadStatuses) => {
        switch (status) {
            case LeadStatuses.valid:
                return <img src={validLeads} alt="status-valid" />;

            case LeadStatuses.reportAccepted:
                return <img src={reportAccepted} alt="status-accepted" />;

            case LeadStatuses.reportRejected:
                return <img src={reportRejected} alt="status-rejected" className="report-rejected" />;

            case LeadStatuses.reported:
                return <img src={reportedLeads} alt="status-reported" />;

            case LeadStatuses.archived:
                return <img src={validLeads} alt="status-archived" className="archived-leads" />;

            default:
                return status;
        }
    }, []);

    const columns = useMemo(
        () => [
            columnHelper.accessor('status', {
                header: () => <span className="ml-3">Status</span>,
                cell: (info) => <span className="status_icon ml-3">{getIcon(info.getValue())}</span>,
            }),
            columnHelper.accessor('firstName', {
                header: 'First Name',
            }),
            columnHelper.accessor('lastName', {
                header: 'Last Name',
            }),
            columnHelper.accessor('phone', {
                header: 'Phone',
            }),
            columnHelper.accessor('email', {
                header: 'email',
            }),
            columnHelper.accessor('_id', {
                header: '',
                cell: (info) => (
                    <div className="row-action-box">
                        <button
                            className={classNames('common-table-btn ', { 'active-expand': info.row.getIsExpanded() })}
                        >
                            More Details
                        </button>
                        <button
                            className={classNames('common-table-btn gray-table-btn', {
                                'active-expand': info.row.getIsExpanded(),
                            })}
                        >
                            Add Notes
                        </button>
                        <button
                            className={classNames('common-table-btn primary-table-btn', {
                                'active-expand': info.row.getIsExpanded(),
                            })}
                        >
                            Report Lead
                        </button>
                    </div>
                ),
            }),
        ],
        [],
    );
    const table = useReactTable({
        data: adminSerializedData ?? [],
        state: { expanded },
        columns,
        onExpandedChange: setExpanded,
        getCoreRowModel: getCoreRowModel(),
    });

    useEffect(() => {
        window.onscroll = function (ev) {
            if (window.innerHeight + window.scrollY >= document.body.offsetHeight && window.innerWidth < 769) {
                // you're at the bottom of the page, load more content here.
                query?.page <= query?.pageCount && setQuery({ ...query, perPage: query?.perPage * 1 + 10 });
            }
        };
    }, [query]);

    return (
        <>
            <div className="table-v2-block">
                <div className="table-list-scroller">
                    <table>
                        <thead>
                            {table.getHeaderGroups().map((headerGroup) => {
                                return (
                                    <tr key={headerGroup.id}>
                                        {headerGroup.headers.map((header) => {
                                            return (
                                                <th key={header.id}>
                                                    {header.isPlaceholder
                                                        ? null
                                                        : flexRender(
                                                              header.column.columnDef.header,
                                                              header.getContext(),
                                                          )}
                                                </th>
                                            );
                                        })}
                                    </tr>
                                );
                            })}
                        </thead>
                        <tbody>
                            {table.getRowModel().rows.map((row) => (
                                <>
                                    <tr
                                        key={row.id}
                                        {...{
                                            onClick: () => {
                                                row.toggleExpanded();
                                            },
                                            style: { cursor: 'pointer' },
                                        }}
                                    >
                                        {row.getVisibleCells().map((cell) => (
                                            <td key={cell.id}>
                                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                            </td>
                                        ))}
                                    </tr>
                                    {row.getIsExpanded() ? (
                                        <>
                                            {/* to be cleared how to passs data fron serialize data which is unknown */}
                                            <ExpandedRow
                                                leadData={row.original}
                                                setExpanded={setExpanded}
                                                adminLeadsData={adminLeadsData}
                                            />
                                        </>
                                    ) : (
                                        ''
                                    )}
                                </>
                            ))}
                        </tbody>
                        {loading && (
                            <tr className="">
                                <td colSpan={7} className="text-align-center">
                                    Loading...
                                </td>
                            </tr>
                        )}
                    </table>
                    {adminSerializedData?.length <= 0 && !loading && <h4 className="table-loading">No data found</h4>}
                </div>
            </div>

            <SpinningComponent loading={loading}>
                <div className="table-v2-block table-v2-responsive">
                    {table.getRowModel().rows.map((row) => {
                        return <ResponsiveTable row={row} getIcon={getIcon} adminLeadsData={adminLeadsData} />;
                    })}
                    {loading && <h4 className="table-loading">Loading...</h4>}
                    {adminSerializedData?.length <= 0 && <h4 className="table-loading">No data found</h4>}
                </div>
            </SpinningComponent>
        </>
    );
};

export default Table;
